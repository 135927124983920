import React, { useEffect, useState } from 'react';
import {
    useHistory
} from 'react-router-dom';
import {
    Card,
    CardContent,
    Container, 
    Grid, 
    Table, 
    TableCell, 
    TableBody, 
    TableFooter, 
    TableContainer, 
    TableHead, 
    TableRow,
    TablePagination,
    Typography
} from '@material-ui/core';
import {Row, Col, Tab} from 'react-bootstrap';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';

interface Column {
    id: 'name' | 'date_created' | 'date_modified' ;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 50 },
    { id: 'date_created', label: 'Date Created', minWidth: 50 },
    {
        id: 'date_modified',
        label: 'Date Modified',
        minWidth: 50,
    }
];

let test =  [
    {
        "id": 192,
        "created": "2021-03-02T06:51:18.795132Z",
        "modified": "2021-03-02T08:17:16.456063Z",
        "name": "MasterCard - 5881",
        "Cardstatement": "https://api.4gives.me/media/e8068264-026f-4b93-9592-8a8c490a4c26.jpg",
        "reference_id": 162,
        "source_type": "PAYMAYA",
        "installmentAuthorized": true,
        "cardCutoff": 3123,
        "cardLimit": "32131.00",
        "spendLimit": "9639.30"
    },
]

const Source: React.FC<{ 
    setLoadingOverlay: (e: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const history = useHistory();
    const [args, setArgs] = useState<any | null>('');
    const [count, setCount] = useState<any | null>(0);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const [sources, setSources] = useState<any| null>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event: unknown, newPage: number) => {
        if(newPage !== page){
            console.log('PAGE: ',newPage+1)
            setPage(newPage);
            if((newPage+1) === 1){
                
                setArgs(`page=1`);
            }else{
                setArgs(`page=${newPage+1}`)
            }
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if(sources === null){
            
            loadSources('')
        }
        
    },[sources])

    useEffect(() => {
        if(sources){
            let newArgs = `?page_size=${pageSize}&&`+args
            loadSources(newArgs)
        }
        
    },[args, pageSize])

    const loadSources = (params: any) => {
        setLoadingOverlay(true)
        Auth.dashboard.getSources(
            params,
            (resp) => {
                console.log(resp)
                setCount(resp.count)
                setLoadingOverlay(false)
                setSources(resp.results)
            },
            (e) => {
                setLoadingOverlay(false)
                console.log(e)
            }
        )
    }
    return(
        <div className="page-content sources">
            <Container>
                <Row>

                </Row>
                <Row>
                    <Card>
                        <CardContent>

                        
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ maxWidth: column.minWidth }}
                                            >
                                            <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                {column.label}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sources && sources.map((sources: any) => {
                                        console.log(sources)
                                        return (
                                            
                                            <TableRow className="source-row" onClick={() => history.push(`/admin/source/${sources.id}`)}>
                                                <TableCell>
                                                    {sources.name}
                                                </TableCell>
                                
                                                <TableCell>
                                                    {moment(sources.created).format("MMMM D, YYYY h:mm A")}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(sources.modified).format("MMMM D, YYYY h:mm A")}
                                                </TableCell>
                                            </TableRow>
                                            
                                        )
                                    })}
                                    
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={test.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        </CardContent>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}

export default Source;