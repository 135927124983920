import { CryptographyServiceInstance } from './cryptography';

type DecryptDataProps = {
  decryptedData: string;
  decryptedIV: string;
};

const decryptData = ({ decryptedData, decryptedIV }: DecryptDataProps) => {
  const { decryptData } = CryptographyServiceInstance;

  const decrypted = decryptData(decryptedData, decryptedIV);

  const dataObj = JSON.parse(decrypted);

  return dataObj;
};

export default decryptData;
