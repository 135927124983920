import React from 'react';
import Cookies from 'js-cookie';
import { IContextAdmin } from '../interface';
import URL from '../services/url';
import { ApiRequest } from '../services/http';
import Auth from '../auth';
import { CryptographyServiceInstance } from '../services/cryptography';

export const AdminUser = {
  Context: React.createContext<IContextAdmin>({
    admin: null,
    setAdmin: () => {},
  }),
  get: (success: (e: any) => void) => {
    const { decryptData } = CryptographyServiceInstance;
    console.log('Getting admin info');

    ApiRequest.get(URL.USER.PROFILE)
      .then((response) => {
        const res = Promise.resolve(response.data);

        res.then((data) => {
          const decryptedData = decryptData(data.data, data.iv);
          const dataObj = JSON.parse(decryptedData);

          Auth.dashboard.getStaffDetails(
            dataObj.id,
            (resp) => {
              let newAdmin = { ...response.data, ...resp };
              console.log(newAdmin);
              success(newAdmin);
            },
            (e) => {
              console.log(e);
            }
          );
        });
      })
      .catch((e) => {
        Cookies.remove('admin-key');
        localStorage.removeItem('admin-key');
        console.log(e);
      });
  },
};
