import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router';
import './style.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import URL from '../../services/url';
import Auth from '../../auth';
import {
  BottomToolbar,
  PasswordConfirmationDialog,
  SaveConfirmationDialog,
} from '../../components';
import { SuccessDialog } from '../../components/SuccessDialog';
import { ErrorDialog } from '../../components/ErrorDialog';
import { ApiRequest } from '../../services/http';
import { set } from 'js-cookie';

interface ConstanceDetails {
  value: any;
}

const ConstanceDetails: FC<{ setLoadingOverlay: (e?: any) => void }> = ({
  setLoadingOverlay,
}) => {
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();
  const [name, setName] = useState('');
  const [value, setValue] = useState<any>();
  const [data_type, setDataType] = useState('');
  //
  const [openPassword, setOpenPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveConfirm, setSaveConfirm] = useState(false);

  // mutation for edit
  const { mutate: addConstance } = useMutation((data: ConstanceDetails) => {
    return ApiRequest.post(URL.DASHBOARD.CONSTANCE, data);
  });

  const { mutate: editConstance } = useMutation(
    (data: ConstanceDetails) => {
      return ApiRequest.patch(`${URL.DASHBOARD.CONSTANCE}/${id}`, data);
    },
    {
      onSuccess: (response) => {
        // if(response.data.value === )

        setValue(response.data.value);
        setLoadingOverlay(false);
        setOpenSuccess(true);
        setDialogMsg('Constance has been updated');
      },
    }
  );

  // get data by id
  const { data, isLoading } = useQuery(
    ['getConstanceById'],
    async () => {
      const response = await fetch(`${URL.DASHBOARD.CONSTANCE}/${id}`, {
        headers: {
          Authorization: `Token ${Auth.key()}`,
        },
      });

      return response.json();
    },
    {
      enabled: !!id,
      onSuccess: (data) => {
        setName(data.key);
        setValue(data.value);
        setDataType(data.data_type);
      },
    }
  );

  // handle submit
  const handleOnSubmit = () => {
    if (type === 'edit') {
      setLoadingOverlay(true);
      setSaveConfirm(false);

      editConstance({ value });
    }
  };

  console.log({
    value: value,
    data_type: data_type,
  });

  return (
    <div className="page-content staff-details">
      <div className="staff-details-container">
        <Container fixed>
          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            spacing={5}
            sm={12}
          >
            <Grid container item direction="row" spacing={2} xs={12}>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <div className="staff-title">
                          <Typography
                            style={{
                              fontSize: '20px',
                            }}
                            color="primary"
                          >
                            Constance Details
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {name.charAt(0).toUpperCase() +
                            name.toLowerCase().slice(1).replace(/_/g, ' ')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {data_type === 'bool' ? (
                          <Switch
                            checked={value === 'true' ? true : false}
                            onChange={(e) =>
                              setValue(e.target.checked ? 'true' : 'false')
                            }
                            color="primary"
                          />
                        ) : (
                          <TextField
                            value={value}
                            name="name"
                            fullWidth
                            // label={
                            //   <Typography>
                            //     {name.toUpperCase().replace(/_/g, ' ')}
                            //   </Typography>
                            // }
                            style={{
                              margin: '8px 8px 8px 0px',
                            }}
                            type={
                              data_type === 'int'
                                ? 'number'
                                : data_type === 'float'
                                ? 'number'
                                : data_type === 'bool'
                                ? 'boolean'
                                : 'text'
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              if (data_type === 'int') {
                                const valueInt = parseInt(e.target.value);
                                setValue(valueInt);
                              } else if (data_type === 'float') {
                                const valueFloat = parseFloat(e.target.value);
                                setValue(valueFloat);
                              } else if (data_type === 'bool') {
                                const valueBool = Boolean(e.target.value);
                                setValue(valueBool);
                              } else {
                                const valueString = String(e.target.value);
                                setValue(valueString);
                              }
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              spacing={2}
              xs={12}
              sm={4}
            ></Grid>
          </Grid>
        </Container>
      </div>
      <PasswordConfirmationDialog
        open={openPassword}
        setOpen={setOpenPassword}
        onFinish={() => saveConfirm && handleOnSubmit()}
      />
      <BottomToolbar
        btnDel={() => setOpenPassword(true)}
        btnSave={() => {
          setSaveConfirm(true);
        }}
      />
      <SaveConfirmationDialog
        open={saveConfirm}
        setOpen={() => setSaveConfirm(false)}
        btnNo={() => setSaveConfirm(false)}
        btnYes={() => setOpenPassword(true)}
      />
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        onClose={() => setSaveConfirm(false)}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
    </div>
  );
};

export default ConstanceDetails;
