import React, { useEffect, useState } from 'react';
import {
    useHistory
} from 'react-router-dom';
import {
    Card,
    CardContent,
    Container, 
    Grid, 
    Table, 
    TableCell, 
    TableBody, 
    TableFooter, 
    TableContainer, 
    TableHead, 
    TableRow,
    TablePagination,
    Typography,
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@material-ui/core';
import {Row, Col, Tab} from 'react-bootstrap';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import { DeleteConfirmationDialog, PasswordConfirmationDialog } from '../../components';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';

interface Column {
    id: 'name' | 'date_created' | 'date_modified' ;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 50 },
    { id: 'date_created', label: 'Date Created', minWidth: 50 },
    {
        id: 'date_modified',
        label: 'Date Modified',
        minWidth: 50,
    }
];

let test =  [
    {
        "id": 192,
        "created": "2021-03-02T06:51:18.795132Z",
        "modified": "2021-03-02T08:17:16.456063Z",
        "name": "MasterCard - 5881",
        "Cardstatement": "https://api.4gives.me/media/e8068264-026f-4b93-9592-8a8c490a4c26.jpg",
        "reference_id": 162,
        "source_type": "PAYMAYA",
        "installmentAuthorized": true,
        "cardCutoff": 3123,
        "cardLimit": "32131.00",
        "spendLimit": "9639.30"
    },
]

const Category = () => {
    const history = useHistory();
    const [args, setArgs] = useState<any | null>('');
    const [create, setCreate] = useState(false);
    const [count, setCount] = useState<any | null>(0);
    const [deleteID, setDeleteID] = useState<any | null>(null);
    const [delConfirm, setDelConfirm] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState(false);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const [service_types, setServiceTypes] = useState<any| null>(null);
    const [categoryName, setCategoryName] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)

    const addCategory = () => {
        Auth.dashboard.addCategory(
            categoryName,
            (resp) => {
                console.log(resp)
                setCategoryName('')
                setCreate(false)
                loadCategories('')
            },
            (e) => {
                console.log(e)
                setError(e.error)
                setOpenErrorDialog(true)
            }
        )
    }

    const delCategory = () => {
        Auth.dashboard.delCategory(
            deleteID,
            (resp) => {
                console.log(resp)
                loadCategories('')
                setDeleteID(null);
                setDelConfirm(false)
                history.push('/admin/categories')
            },
            (e) => {
                console.log(e)
                setError(e.error)
                setOpenErrorDialog(true)
            }
        )
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        if(newPage !== page){
            console.log('PAGE: ',newPage+1)
            setPage(newPage);
            if((newPage+1) === 1){
                
                setArgs(`page=1`);
            }else{
                setArgs(`page=${newPage+1}`)
            }
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if(service_types === null){
            
            loadCategories('')
        }
        
    },[service_types])

    useEffect(() => {
        if(service_types){
            let newArgs = `?page_size=${pageSize}&&`+args
            loadCategories(newArgs)
        }
        
    },[args, pageSize])

    const loadCategories = (params: any) => {
        Auth.dashboard.getCategory(
            params,
            (resp) => {
                console.log(resp)
                setCount(resp.count)
                setServiceTypes(resp.results)
            },
            (e) => {
                console.log(e)
            }
        )
    }
    return(
        <div className="page-content sources">
            <Container>
                <Row>
                    <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
                        <Toolbar>
                            <Typography style={{fontSize: '20px', fontWeight: 'bold'}} color="primary">
                                Categories
                            </Typography>
                            <Box display='flex' flexGrow={1} />
                            <div className="button-tool" onClick={()=> setCreate(true)}>
                                <Typography style={{fontSize: 16, color: 'white'}}>Create Category</Typography>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Row>
                <Row>
                    <Card>
                        <CardContent>

                        
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ maxWidth: column.minWidth }}
                                            >
                                            <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                {column.label}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {service_types && service_types.map((serviceType: any) => {
                                        console.log(serviceType)
                                        return (
                                            
                                            <TableRow className="source-row" >
                                                <TableCell>
                                                    {serviceType.name}
                                                </TableCell>
                                
                                                <TableCell>
                                                    {moment(serviceType.created).format("MMMM D, YYYY h:mm A")}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(serviceType.modified).format("MMMM D, YYYY h:mm A")}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => {setDeleteID(serviceType.id); setDelConfirm(true)}}>
                                                        <DeleteIcon style={{fill: 'red'}}/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            
                                        )
                                    })}
                                    
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        </CardContent>
                    </Card>
                </Row>
            </Container>
            <Dialog open={create} fullWidth onClose={() => setCreate(false)}>
                <DialogTitle>
                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                        Create Payment Type
                    </Typography>
                        
                </DialogTitle>
                <DialogContent>
                    <TextField 
                        name="payment_name"
                        value={categoryName}
                        label="Payment Type Name"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setCategoryName(e.target.value)}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <div className="button-tool" >
                        <Typography style={{fontSize: 16, color: 'white'}} onClick={addCategory}>Save</Typography>
                    </div>
                </DialogActions>
            </Dialog>
            <PasswordConfirmationDialog open={passwordConfirm} setOpen={() => setPasswordConfirm(false)} onFinish={delCategory}/>
            <DeleteConfirmationDialog open={delConfirm} setOpen={()=>setDelConfirm(false)} btnNo={() => {setDelConfirm(false); setDeleteID(null)}} btnYes={() => setPasswordConfirm(true)}/>
            <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg}/>
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </div>
    )
}

export default Category;