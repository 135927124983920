import React, { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import './style.scss';
import { BottomToolbar, PasswordConfirmationDialog } from '../../components';
import { StaffParamTypes } from '../../interface';
import { useHistory, useParams } from 'react-router';
import Auth from '../../auth';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';
import CompaniesList from '../../components/CompaniesList';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import URL from '../../services/url';
import { ApiRequest } from '../../services/http';

let test = {
  id: 3,
  first_name: 'Testingg',
  last_name: 'Staff',
  email: 'testing@test.com',
  has_full_access: true,
  manage_users: false,
  manage_transactions: false,
  manage_proposal: false,
  manage_staffs: false,
};

interface Address {
  street: string;
  barangay: string;
  city: string;
  region: string;
  zip_code: string;
}

interface Employee {
  company_id: number;
  first_name: string;
  last_name: string;
  email: string;
  salary: string;
  years_on_company: string;
  contact_number: string;
  gender: string;
  date_of_birth: string;
  address: Address;
}

const UserCreate: React.FC<{
  setLoadingOverlay: (e: any) => void;
}> = ({ setLoadingOverlay }) => {
  const { id, type } = useParams<StaffParamTypes>();
  const [first_name, setFirstName] = useState<any>('');
  const [last_name, setLastName] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [openPassword, setOpenPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedCompanyID, setSelectedCompanyID] = useState(0);
  const [contact_number, setContactNumber] = useState('');
  const [street, setStreet] = useState('');
  const [barangay, setBarangay] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [years_on_company, setYearsOnCompany] = useState('');
  const [salary, setSalary] = useState('');
  const [gender, setGender] = useState('');
  const [date_of_birth, setDateOfBirth] = useState('');

  const { data, mutate } = useMutation(
    (employeeData: Employee) => {
      return ApiRequest.post(URL.DASHBOARD.EMPLOYEE_CREATE, employeeData);
    },
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setDialogMsg('Employee created');
        window.location.reload();
      },
      onError: (e, variables, context) => {
        setError('Email already exists');
        setOpenErrorDialog(true);
      },
    }
  );

  const { data: csvUploadData, mutate: uploadFile } = useMutation(
    (input: FormData) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${Auth!.key()}`,
        },
      };

      return axios.post(URL.DASHBOARD.EMPLOYEE_BULK_UPLOAD, input, config);
    },
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setDialogMsg('Employees created');
      },
    }
  );
  const history = useHistory();

  const createEmployee = () => {
    mutate({
      first_name,
      last_name,
      email,
      salary,
      years_on_company,
      contact_number,
      company_id: selectedCompanyID,
      address: {
        street,
        barangay,
        city,
        region,
        zip_code,
      },
      gender,
      date_of_birth,
    });
  };

  const deleteStaff = () => {
    Auth.dashboard.deleteStaff(
      id,
      (resp) => {
        history.goBack();
      },
      (e) => {
        console.log(e);
        setError(e.error);
        setOpenErrorDialog(true);
      }
    );
  };

  const handleOnFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('csv_file', file);
      formData.append('company_id', selectedCompanyID.toString());

      uploadFile(formData);
      event.target.value = '';
    }
  };

  return (
    <div className="page-content staff-details">
      <div className="staff-details-container">
        <Container fixed>
          <div
            className="staff-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              style={{
                fontSize: '20px',
              }}
              color="primary"
            >
              Add Employee
            </Typography>

            <Button
              variant="contained"
              component="label"
              color="primary"
              size="small"
            >
              File Upload (BULK)
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={(event) => handleOnFileUpload(event)}
              />
            </Button>
          </div>
          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            spacing={5}
            sm={12}
          >
            <Grid container direction="row" spacing={2} xs={8} item>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <CompaniesList
                          setSelectedCompanyID={setSelectedCompanyID}
                          label="Company"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={first_name}
                          name="first_name"
                          fullWidth
                          label={<Typography>First Name</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={last_name}
                          name="last_name"
                          fullWidth
                          label={<Typography>Last Name</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={email}
                          type="email"
                          name="email"
                          fullWidth
                          label={<Typography>Email</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={contact_number}
                          name="email"
                          fullWidth
                          label={<Typography>Contact Number</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          type="number"
                          onChange={(e) => setContactNumber(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Gender</FormLabel>
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                          >
                            <FormControlLabel
                              value="M"
                              control={
                                <Radio
                                  color="primary"
                                  onChange={() => setGender('M')}
                                />
                              }
                              label="Male"
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              value="F"
                              control={
                                <Radio
                                  color="primary"
                                  onChange={() => setGender('F')}
                                />
                              }
                              label="Female"
                              labelPlacement="start"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <Typography>Date of birth</Typography>
                        <TextField
                          fullWidth
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          type="date"
                          onChange={(e) => setDateOfBirth(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              spacing={2}
              xs={12}
              sm={4}
            ></Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            spacing={5}
            sm={12}
          >
            <Grid container direction="row" spacing={2} xs={8} item>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={6}>
                        <TextField
                          value={street}
                          name="street"
                          fullWidth
                          label={<Typography>Street</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={barangay}
                          name="barangay"
                          fullWidth
                          label={<Typography>Barangay</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setBarangay(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={city}
                          name="city"
                          fullWidth
                          label={<Typography>City</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={region}
                          name="email"
                          fullWidth
                          label={<Typography>Province</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setRegion(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={zip_code}
                          name="email"
                          fullWidth
                          label={<Typography>Zip Code</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              spacing={2}
              xs={12}
              sm={4}
            ></Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            spacing={5}
            sm={12}
          >
            <Grid container direction="row" spacing={2} xs={8} item>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={years_on_company}
                          name="city"
                          fullWidth
                          label={
                            <Typography># of years in the company</Typography>
                          }
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          type="number"
                          variant="outlined"
                          onChange={(e) => setYearsOnCompany(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={salary}
                          name="email"
                          fullWidth
                          label={<Typography>Salary</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          type="number"
                          variant="outlined"
                          onChange={(e) => setSalary(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              spacing={2}
              xs={12}
              sm={4}
            ></Grid>
          </Grid>
        </Container>
      </div>
      <PasswordConfirmationDialog
        open={openPassword}
        setOpen={setOpenPassword}
        onFinish={deleteStaff}
      />
      <BottomToolbar btnSave={createEmployee} />
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
    </div>
  );
};

export default UserCreate;
