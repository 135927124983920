import axios from 'axios';
import URL from './url';
import Auth from '../auth';

let httpRequest;

const config = {
  baseURL: URL.SERVER,
  timeout: 30000, //30 seconds
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: '',
  },
};

export const ApiRequest = {
  get: (url: string) => {
    console.log('getting request.....');
    console.log(url);
    console.log(Auth.key());
    config.headers.Authorization = `Token ${Auth.key()}`;
    console.log(config.headers.Authorization);
    httpRequest = axios.create(config);
    console.log(config);
    console.log('request success!');
    return httpRequest.get(url);
  },
  post: (url: string, args = {}) => {
    console.log('posting.,..');
    config.headers.Authorization = `Token ${Auth.key()}`;
    httpRequest = axios.create(config);
    console.log('posting done!');
    return httpRequest.post(url, args);
  },
  patch: (url: string, args = {}) => {
    config.headers.Authorization = `Token ${Auth.key()}`;
    httpRequest = axios.create(config);
    return httpRequest.patch(url, args);
  },
  put: (url: string, args = {}) => {
    config.headers.Authorization = `Token ${Auth.key()}`;
    httpRequest = axios.create(config);
    return httpRequest.put(url, args);
  },
  delete: (url: string, args = {}) => {
    config.headers.Authorization = `Token ${Auth.key()}`;
    httpRequest = axios.create(config);
    return httpRequest.delete(url, args);
  },
};

export const HttpRequest = {
  get: (url: string) => {
    httpRequest = axios.create(config);
    return httpRequest.get(url);
  },
  post: (url: string, args = {}) => {
    httpRequest = axios.create(config);
    return httpRequest.post(url, args);
  },
};
