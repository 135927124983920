/* eslint-disable jsx-a11y/alt-text */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Switch,
  Route,
  RouteProps,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import SideBar from '../SideBar';
import routes from '../../routes/routes';
import '../../assets/styles/global.scss';
import { AdminUser } from '../../models';
import './style.scss';
import {
  Ads,
  Category,
  Companies,
  CompanyDetails,
  Home,
  PaymentSchedules,
  PaymentTypes,
  PayoutDetails,
  Payouts,
  ProposalDetails,
  Proposals,
  Source,
  SourceDetails,
  Staff,
  StaffDetails,
  TransactionDetails,
  Transactions,
  UserCreate,
  UserDetails,
  Users,
  Constance,
  ConstanceDetails,
} from '../../pages';
import {
  AppBar,
  Backdrop,
  Button,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { amber } from '@material-ui/core/colors';
import Auth from '../../auth';
import useClickOutside from '../../hooks/useClickOutside';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logout from '../../assets/images/logout.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  user: {
    cursor: 'pointer',
  },
  appBarButton: {
    borderRadius: '50px',
    backgroundColor: 'transparent',
    color: '#fff',
    textAlign: 'center',
    padding: '5px',
    width: '350px',
    fontSize: '13px',
  },
  appBarLabel: {
    color: '#f47223',
    textAlign: 'center',
    fontSize: '13px',
  },
}));

const Admin: React.FC<{
  setLoadingOverlay: (e: boolean) => void;
}> = ({}) => {
  const classes = useStyles();
  const { admin, setAdmin } = useContext(AdminUser.Context);
  const [page, setPage] = useState('');
  const location = useLocation();
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [profileMenu, setProfileMenu] = useState<boolean>(false);
  const userContainer = useRef<HTMLDivElement>(null);

  const history = useHistory();

  const toggleMenu = useCallback(() => {
    setProfileMenu(!profileMenu);
  }, []);

  useClickOutside(userContainer, () => {
    setProfileMenu(false);
  });

  useEffect(() => {
    AdminUser.get((admin: any) => {
      setAdmin(admin);
    });

    /**/
  }, []);

  console.log('GET ADMIN');

  if (admin) {
    return (
      <div className="page-wrapper">
        {loadingOverlay ? (
          <Backdrop className={classes.backdrop} open={loadingOverlay}>
            <BounceLoader color={'#f47223'} />
          </Backdrop>
        ) : null}
        <AppBar
          position="sticky"
          style={{ background: 'transparent', boxShadow: 'none' }}
        >
          <Toolbar>
            <div style={{ flexGrow: 1 }}></div>
            <div className={classes.user} ref={userContainer}>
              <Grid
                container
                item
                direction="row"
                className={classes.appBarButton}
                onClick={() => toggleMenu()}
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={9}>
                  <Typography className={classes.appBarLabel} variant="body1">
                    {admin.email + '(DEV)'}
                  </Typography>
                  <div
                    className={`user-menu-container ${
                      profileMenu && 'user-menu-container-open'
                    }`}
                  >
                    <ul className="user_menu">
                      <li
                        onClick={() => {
                          Auth.logout();
                          setAdmin(null);
                          history.push('/');
                        }}
                      >
                        <Grid container direction="row">
                          <Grid item>
                            <img
                              style={{
                                width: '26px',
                                height: 'auto',
                              }}
                              src={logout}
                            />
                          </Grid>
                          <Grid item>
                            <span>Logout</span>
                          </Grid>
                        </Grid>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <ExpandMoreIcon
                    fontSize="small"
                    style={{ fill: '#f47223' }}
                  />
                </Grid>
              </Grid>
            </div>
          </Toolbar>
        </AppBar>

        <SideBar data={admin} />
        <Switch>
          <Route
            exact
            path="/admin"
            render={() => <Redirect to="/admin/home" />}
          />
          {/***routes.private.map((page, index) => 
                        <Route path={page.url} key={`route_${index}`} component={page.component}/>
                    
                )*/}
          <Route
            path="/admin/home"
            render={(props: any) => (
              <Home {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/payment-types"
            render={(props: any) => (
              <PaymentTypes {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/categories"
            render={(props: any) => (
              <Category {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/source/:id"
            render={(props: any) => (
              <SourceDetails {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/users"
            render={(props: any) => (
              <Users {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/user/:id"
            render={(props: any) => (
              <UserDetails
                {...props}
                admin={admin}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
          <Route
            path="/admin/proposals"
            render={(props: any) => (
              <Proposals {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/proposal/:id"
            render={(props: any) => (
              <ProposalDetails
                {...props}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
          <Route
            path="/admin/payouts"
            render={(props: any) => (
              <Payouts {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/payout/:id"
            render={(props: any) => (
              <PayoutDetails {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/transactions"
            render={(props: any) => (
              <Transactions {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/staffs"
            render={(props: any) => (
              <Staff {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/transaction/:id"
            render={(props: any) => (
              <TransactionDetails
                {...props}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
          <Route
            path="/admin/staff/:type/:id?"
            render={(props: any) => (
              <StaffDetails {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/payment-schedules"
            render={(props: any) => (
              <PaymentSchedules
                {...props}
                admin={admin}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
          <Route
            path="/admin/sources"
            render={(props: any) => (
              <Source
                {...props}
                admin={admin}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
          <Route
            path="/admin/ads/view/:id"
            render={(props: any) => (
              <Ads {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/ads"
            render={(props: any) => (
              <Ads {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/ads/:action"
            render={(props: any) => (
              <Ads {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/employee/create"
            render={(props: any) => (
              <UserCreate {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/companies"
            exact
            render={(props: any) => (
              <Companies {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/companies/:type/:id?"
            render={(props: any) => (
              <CompanyDetails
                {...props}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
          <Route
            path="/admin/constance"
            exact
            render={(props: any) => (
              <Constance {...props} setLoadingOverlay={setLoadingOverlay} />
            )}
          />
          <Route
            path="/admin/constance/:type/:id?"
            render={(props: any) => (
              <ConstanceDetails
                {...props}
                setLoadingOverlay={setLoadingOverlay}
              />
            )}
          />
        </Switch>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Admin;
