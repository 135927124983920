/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Box,
  FormGroup,
  FormLabel,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Card,
  CardContent,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import URL from '../../services/url';
import Auth from '../../auth';
import { useHistory } from 'react-router';

const useStyles = makeStyles<any | null>((theme) => ({
  form: {
    width: '70%',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)',
    padding: theme.spacing(3),
  },
  input: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  button: {
    // or "auto
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  //remove scroll bar with css
  container: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const Constance: React.FC<{
  setLoadingOverlay: (e?: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState<any | null>(0);
  const [pageSize, setPageSize] = useState<any | null>(10);
  const [args, setArgs] = useState<any | null>('');
  const [rowsPerPage, setRowsPerPage] = useState<any | null>(10);
  const [count, setCount] = useState<any | null>(0);
  const [constance, setConstance] = useState<any | null>([]);

  const { data, isLoading } = useQuery(['constance'], async () => {
    const response = await (
      await fetch(URL.DASHBOARD.CONSTANCE, {
        headers: new Headers({
          Authorization: `Token ${Auth.key()}`,
        }),
      })
    ).json();

    return response;
  });

  console.log('CONSTANCE: ', data);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage !== page) {
      console.log('PAGE: ', newPage + 1);
      setPage(newPage);
      if (newPage + 1 === 1) {
        setArgs(`page=1`);
      } else {
        setArgs(`page=${newPage + 1}`);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageSize(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let newArgs = `?page_size=${pageSize}${args !== '' ? `&${args}` : ''}`;

    loadConstance(newArgs);
  }, [args, pageSize, page]);

  const loadConstance = async (newArgs: string) => {
    setLoadingOverlay(true);
    const response = await (
      await fetch(`${URL.DASHBOARD.CONSTANCE}${newArgs}`, {
        headers: new Headers({
          Authorization: `Token ${Auth.key()}`,
        }),
      })
    ).json();
    setLoadingOverlay(false);
    console.log('CONSTANCE: ', response);
    setCount(response.count);
    setConstance(response.results);
  };

  const tableHeader = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 170,
    },
    {
      id: 'value',
      label: 'Value',
      minWidth: 170,
    },
  ];

  return (
    <>
      <Box
        style={{
          margin: '0 auto',
          height: '875px',
        }}
        className={classes.form}
      >
        <Typography
          style={{ fontSize: '22px', fontWeight: 'bold', marginBottom: '20px' }}
          color="primary"
        >
          Constance
        </Typography>

        <Card>
          <CardContent
            style={{
              padding: '0',
              height: '700px',
              overflowY: 'scroll',
            }}
            className={classes.container}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeader.map((column) => (
                      <TableCell
                        key={column.id}
                        align={'left'}
                        style={{
                          maxWidth: column.minWidth,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                          color="primary"
                        >
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {constance.map((constance: any) => {
                    return (
                      <TableRow
                        style={{
                          height: '50px',
                          cursor: 'pointer',
                        }}
                        key={constance.id}
                        onClick={() =>
                          history.push(`/admin/constance/edit/${constance.id}`)
                        }
                      >
                        <TableCell>
                          {constance.key.toUpperCase().replace(/_/g, ' ')}
                        </TableCell>
                        <TableCell>
                          {constance.value === false || constance.value === true
                            ? constance.value === true
                              ? 'True'
                              : 'False'
                            : constance.value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableFooter>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        {/* <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',

            justifyContent: 'flex-end',
            width: '100%',
            gap: '20px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Cancel
          </Button>
        </Box> */}
      </Box>
    </>
  );
};

export default Constance;
