/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Card,
  CardContent,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Row } from 'react-bootstrap';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '../../components';
import { AdminUser } from '../../models';
interface Column {
  id: 'email' | 'username' | 'first_name' | 'last_name' | 'date_joined';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'email', label: 'Email', minWidth: 50 },
  { id: 'username', label: 'Username', minWidth: 50 },
  {
    id: 'first_name',
    label: 'First Name',
    minWidth: 50,
  },
  {
    id: 'last_name',
    label: 'Last Name',
    minWidth: 50,
  },
  {
    id: 'date_joined',
    label: 'Date Joined',
    minWidth: 50,
  },
];

const Users: React.FC<{
  setLoadingOverlay: (e: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const history = useHistory();
  const { admin } = useContext(AdminUser.Context);
  const [args, setArgs] = useState<any | null>('');
  const [count, setCount] = useState<any | null>(0);
  const [users, setUsers] = useState<any | null>(null);
  const [emailConfirmed, setEmailConfirmed] = useState<any | null>(false);
  const [buyAuthorized, setBuyAuthorized] = useState<any | null>(false);
  const [sellAuthorized, setSellAuthorized] = useState<any | null>(false);
  const [applying_for_installment, setApplyingForInstallment] = useState<
    any | null
  >(false);
  const [applying_for_merchant, setApplyingForMerchant] = useState<any | null>(
    false
  );
  const [has_failed_payments, setHasFailedPayments] = useState<any | null>(
    false
  );
  const [pageSize, setPageSize] = useState<any | null>(10);
  const [searchEmail, setSearchEmail] = useState<any | null>('');
  const [searchContact, setSearchContact] = useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage !== page) {
      console.log('PAGE: ', newPage + 1);
      setPage(newPage);
      if (newPage + 1 === 1) {
        setArgs(`page=1`);
      } else {
        setArgs(`page=${newPage + 1}`);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageSize(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setLoadingOverlay(true);
    if (users === null) {
      loadUsers('');
    }else{
      setLoadingOverlay(false);
    }
  }, [users]);

  useEffect(() => {
    let newArgs =
      `?page_size=` +
      `${pageSize}${args !== '' ? `&${args}` : ''}` +
      `${searchEmail !== '' ? `&email=${searchEmail}` : ''}` +
      `${emailConfirmed ? `&emailConfirmed=${emailConfirmed}` : ''}` +
      `${buyAuthorized ? `&buyAuthorized=${buyAuthorized}` : ''}` +
      `${sellAuthorized ? `&sellAuthorized=${sellAuthorized}` : ''}` +
      `${
        has_failed_payments ? `&has_failed_payments=${has_failed_payments}` : ''
      }` +
      `${
        applying_for_installment
          ? `&applying_for_installment=${applying_for_installment}`
          : ''
      }` +
      `${
        applying_for_merchant
          ? `&applying_for_merchant=${applying_for_merchant}`
          : ''
      }` +
      `${searchContact !== '' ? `&contact_number=${searchContact}` : ''}` +
      ``;
    loadUsers(newArgs);
  }, [
    args,
    searchContact,
    pageSize,
    emailConfirmed,
    buyAuthorized,
    sellAuthorized,
    has_failed_payments,
    applying_for_installment,
    applying_for_merchant,
    searchEmail,
  ]);

  const loadUsers = (params: any) => {
    // setLoadingOverlay(true);
    Auth.dashboard.getUsers(
      params,
      (resp) => {
        
        setLoadingOverlay(false);
        // console.log(resp);
        // console.log(resp.results);
        setCount(resp.count);
        setUsers(resp.results);
      },
      (e) => {
        setLoadingOverlay(false);
        console.log(e);
      }
    );
  };

  return (
    <div className="page-content users">
      <Container>
        <Row></Row>

        <Row>
          <Card>
            <CardContent>
              <div className="table-title" style={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    justifySelf: 'center',
                    flexGrow: 1,
                  }}
                  color="primary"
                >
                  Users
                </Typography>
                {admin.has_full_access && (
                  <Button
                    color="primary"
                    onClick={() => history.push('/admin/employee/create')}
                    className={'add-user'}
                  >
                    Add Customer/Employee
                  </Button>
                )}
              </div>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    color="primary"
                    style={{
                      fontSize: '16px',
                      fontStyle: 'bold',
                    }}
                  >
                    Filters
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                    justify="center"
                  >
                    {/*Grid item xs={12} sm={2}>
                                        <Typography style={{fontSize: '16px', fontWeight: 'bold', marginTop: 'auto', marginBottom: 'auto'}} color="primary">
                                            Email   
                                        </Typography>
                                    </Grid>
                                    */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="input-with-icon-textfield"
                        label="Search Email"
                        fullWidth
                        placeholder="Email"
                        onChange={(e) => setSearchEmail(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/***
                                     * 
                                     
                                    <Grid item xs={12} sm={2}>
                                        <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                            Contact Number   
                                        </Typography>
                                    </Grid>
                                    */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="input-with-icon-textfield"
                        label="Contact Number"
                        fullWidth
                        placeholder="Contact Number (e.g. 9123456789)"
                        onChange={(e) => {
                          setSearchContact(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={emailConfirmed}
                            onChange={() => setEmailConfirmed(!emailConfirmed)}
                            name="active"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Email Confirmed
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={buyAuthorized}
                            onChange={() => setBuyAuthorized(!buyAuthorized)}
                            name="active"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Buy Authorized
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sellAuthorized}
                            onChange={() => setSellAuthorized(!sellAuthorized)}
                            name="active"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Sell Authorized
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={applying_for_installment}
                            onChange={() =>
                              setApplyingForInstallment(
                                !applying_for_installment
                              )
                            }
                            name="active"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Applying for Installment
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={applying_for_merchant}
                            onChange={() =>
                              setApplyingForMerchant(!applying_for_merchant)
                            }
                            name="active"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Applying for Merchant
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={has_failed_payments}
                            onChange={() =>
                              setHasFailedPayments(!has_failed_payments)
                            }
                            name="active"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            Has Failed Payments
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            maxWidth: column.minWidth,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                            }}
                            color="primary"
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users.map((user: any) => {
                        return (
                          <TableRow
                            key={user.id}
                            className="user-row"
                            onClick={() =>
                              history.push(`/admin/user/${user.id}`)
                            }
                          >
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{user.first_name}</TableCell>
                            <TableCell>{user.last_name}</TableCell>
                            <TableCell>
                              {moment(user.date_joined).format(
                                'MMMM D, YYYY h:mm A'
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default Users;
