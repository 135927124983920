import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import Auth from '../../auth';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  RadioGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Radio,
  Grid,
  makeStyles,
  Typography,
  TableFooter,
  TablePagination,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import { MoneyLabel } from '../../components';
import './style.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory, useLocation } from 'react-router';
import HomeApplyingUsersTable from './HomeApplyingUsersTable';
import HomeFailedPaymentsTable from './HomeFailedPaymentsTable';
import moment from 'moment';
import MyURL from '../../services/url';
import { AdminUser } from '../../models';
import URL from '../../services/url';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    alignItems: 'center',
  },
  accordion: {
    height: '100%',
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: '0.03333em',
    lineHeight: 1.66,
  },
}));

const Home = () => {
  const classes = useStyles();
  const [args, setArgs] = useState<any>('?period=TODAY');
  const { admin, setAdmin } = useContext(AdminUser.Context);
  const location = useLocation();
  const [total_payments_captured, setTotalPayments] = useState<any>(0);
  const [total_transactions_count, setTotalTransactions] = useState<any>(0);
  const [organizationCeiling, setOrganzationCeiling] = useState(0);
  const [totalAllowableLoan, setTotalAllowableLoan] = useState(0);
  const [period, setPeriod] = useState<any>('TODAY');

  const { data: serverTime, isLoading: isServerTimeLoading } = useQuery(
    ['serverTime'],
    async () => {
      const response = await (await fetch(URL.SERVER_TIME)).json();
      console.log('Server time', response);
      return response;
    }
  );

  const [startDate, setStartDate] = useState(
    moment().subtract(3, 'months').format('YYYY-MM-DD').toString()
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString()
  );

  const [total_proposals_count, setTotalProposals] = useState<any>(0);
  const [current_transactions_count, setCurrentTransactionsCount] =
    useState<any>(0);
  const [waiting_proposals_count, setWaitingProposalsCount] = useState<any>(0);
  const [accepted_proposals_count, setAcceptedProposalsCount] =
    useState<any>(0);
  const [completed_transactions_count, setCompletedTransactions] =
    useState<any>(0);
  const [merchantSiteCount, setMerchantSiteCount] = useState<any>(0);

  const subscribe = async () => {
    if (admin) {
      let events: any = new EventSource(URL.EVENTS.DASHBOARD);
      console.log('SUBSCRIBING');
      events.onmessage = (event: any) => {
        switch (event.type) {
          case 'message':
            const data = JSON.parse(event.data);
            console.log('Event data', data);
            /*const updateActivities = [...activities]
                  updateActivities.push(data)
                  console.log(updateActivities);
                  setActivities(updateActivities);
                  */
            if (
              data.event === 'dashboard_home' &&
              data.action === 're-render'
            ) {
              loadSummary();
            } else {
              events.close();
            }

            break;
        }
      };

      events.onerror = (e: any) => {
        console.log(e);
        events.close();
        events = null;
      };
      if (!location.pathname.includes('home')) {
        console.log('CLOSING');
        events.close();
        events = null;
      }
      return () => {
        events.close();
      };
    }
  };

  useEffect(() => {
    if (total_payments_captured === 0) {
      loadSummary();
      subscribe();
    }
  });

  useEffect(() => {
    loadSummary();
  }, [args]);

  const handleFilterChange = (value: string) => {
    if (value === 'TODAY') {
      setArgs('?period=TODAY');
    } else if (value === 'THIS_MONTH') {
      setArgs('?period=THIS_MONTH');
    } else if (value === 'LAST_THREE_MONTHS') {
      setArgs('?period=LAST_THREE_MONTHS');
    }
  };

  useEffect(() => {
    setArgs(
      `?${startDate !== '' ? `gte=${startDate}` : ''}&${
        endDate !== '' ? `lte=${endDate}` : ''
      } `
    );
  }, [startDate, endDate]);

  const loadSummary = () => {
    Auth.dashboard.getSummary(
      args,
      (sumResp) => {
        console.log('Summary', sumResp);
        setTotalPayments(sumResp.total_payments_captured);
        setTotalTransactions(sumResp.total_transactions_count);
        setTotalProposals(sumResp.total_proposals_count);
        setCurrentTransactionsCount(sumResp.current_transactions_count);
        setWaitingProposalsCount(sumResp.waiting_proposals_count);
        setAcceptedProposalsCount(sumResp.accepted_proposals_count);
        setCompletedTransactions(sumResp.completed_transactions_count);
        setMerchantSiteCount(sumResp.total_from_partner_merchant);
        setOrganzationCeiling(sumResp.organization_ceiling);
        setTotalAllowableLoan(sumResp.total_allowable_loan_amount);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  return (
    <div className="page-content home">
      <Container>
        {/**
                             * 
                             * <RadioGroup aria-label="period" name="period" value={period} onChange={(e) => {setPeriod(e.target.value); handleFilterChange(e.target.value)}}>
                                <FormControlLabel value="TODAY" control={<Radio />} label="Today" />
                                <FormControlLabel value="THIS_MONTH" control={<Radio />} label="This Month" />
                                <FormControlLabel value="LAST_THREE_MONTHS" control={<Radio />} label="Last 3 Months" />
                            </RadioGroup>
                             */}
        <Grid
          style={{ padding: '10px 0px' }}
          container
          alignItems="center"
          justify="flex-start"
          spacing={3}
        >
          <Grid item>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e: any) => setStartDate(e.target.value)}
              InputProps={{
                inputProps: {
                  min: moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD')
                    .toString(),
                  max: moment().format('YYYY-MM-DD').toString(),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="date"
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e: any) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD')
                    .toString(),
                  max: moment().format('YYYY-MM-DD').toString(),
                },
              }}
            />
          </Grid>

          <Grid item>
            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Server time
            </Typography>
            {!isServerTimeLoading && (
              <Typography style={{ fontSize: '16px' }}>
                {moment(serverTime.server_time).format('MMMM D, YYYY h:mm A')}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={1}
          sm={12}
          alignItems="stretch"
          justify="center"
        >
          <Grid item xs={12} sm={3}>
            <Card className={classes.card}>
              <CardContent>
                <Grid
                  container
                  item
                  direction="column"
                  justify="center"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                      Total Payments Captured
                    </Typography>
                  </Grid>
                  <Grid item>
                    {total_payments_captured ? (
                      <MoneyLabel
                        isMoney={true}
                        style={'home-amount'}
                        value={total_payments_captured}
                      />
                    ) : (
                      0
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                      4Gives Ceiling
                    </Typography>
                  </Grid>
                  <Grid item>
                    {organizationCeiling ? (
                      <MoneyLabel
                        isMoney={true}
                        style={'home-amount'}
                        value={organizationCeiling}
                      />
                    ) : (
                      0
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                      Total Remaining Allowable Loan Amount
                    </Typography>
                  </Grid>
                  <Grid item>
                    {total_payments_captured ? (
                      <MoneyLabel
                        isMoney={true}
                        style={'home-amount'}
                        value={totalAllowableLoan}
                      />
                    ) : (
                      0
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card className={classes.card}>
              <CardContent>
                <Grid
                  container
                  item
                  direction="column"
                  justify="center"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                      Current Transactions
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount'}
                      value={current_transactions_count}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card className={classes.card}>
              <CardContent>
                <Grid
                  container
                  item
                  direction="column"
                  justify="center"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                      Waiting Proposals
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount'}
                      value={waiting_proposals_count}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container item xs={12} sm={6} justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '15px', marginBottom: '10px' }}
                    >
                      Total Transactions
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount-small'}
                      value={total_transactions_count}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '15px', marginBottom: '10px' }}
                    >
                      Total Proposals
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount-small'}
                      value={total_proposals_count}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '15px', marginBottom: '10px' }}
                    >
                      Accepted Proposals
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount-small'}
                      value={accepted_proposals_count}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '15px', marginBottom: '10px' }}
                    >
                      Completed Transactions
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount-small'}
                      value={completed_transactions_count}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      style={{ fontSize: '15px', marginBottom: '10px' }}
                    >
                      From Merchant Site Transactions
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MoneyLabel
                      isMoney={false}
                      style={'home-amount-small'}
                      value={merchantSiteCount}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          style={{ paddingTop: '20px' }}
          container
          item
          xs={12}
          spacing={1}
          sm={12}
          alignItems="stretch"
          justify="center"
        >
          <Grid item xs={12} sm={6}>
            <HomeApplyingUsersTable classes={classes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HomeFailedPaymentsTable classes={classes} />
          </Grid>
        </Grid>
        {/**
                     * <Grid container xs={12} sm={8} spacing={3} direction="column" alignItems="center" justify="flex-start">
                        <Grid container item direction="row" spacing={2} alignItems="center" justify="center" >
                            <Grid item>
                                <Card style={{minWidth: '250px'}}>
                                    <CardContent>
                                        <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                            Total Payments Captured
                                        </Typography>
                                        <MoneyLabel isMoney={true} style={'home-amount'} value={total_payments_captured}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card style={{minWidth: '250px'}}>
                                    <CardContent>
                                        <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                            Total Transactions Count
                                        </Typography>
                                        <MoneyLabel isMoney={false} style={'home-amount'} value={total_transactions_count}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card style={{minWidth: '250px'}}>
                                    <CardContent>
                                        <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                            Total Proposals Count
                                        </Typography>
                                        <MoneyLabel isMoney={false} style={'home-amount'} value={total_proposals_count}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={2} alignItems="center" justify="center">
                            <Grid item >
                                <Card style={{minWidth: '250px'}}>
                                    <CardContent>
                                        <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                            Current Transactions
                                        </Typography>
                                        <MoneyLabel isMoney={false} style={'home-amount'} value={current_transactions_count}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item >
                                <Card style={{minWidth: '250px'}}>
                                    <CardContent>
                                        <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                            Waiting Proposals
                                        </Typography>
                                        <MoneyLabel isMoney={false} style={'home-amount'} value={waiting_proposals_count}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item >
                                <Card style={{minWidth: '250px'}}>
                                    <CardContent>
                                        <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                            Accepted Proposals
                                        </Typography>
                                        <MoneyLabel isMoney={false} style={'home-amount'} value={accepted_proposals_count}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            
                        </Grid>
                       
                        
         
                    </Grid>
                    <Grid container item direction="column" xs={12} sm={4} spacing={2} alignItems="center" justify="flex-start" >
                        
                        <Card style={{minWidth: '400px'}}>
                            <CardContent>
                                <Typography color="primary" style={{fontSize: '16px', marginBottom: '10px'}}>
                                    Recent User Activities
                                </Typography>
                                {activities && activities.map((activity: any) => {
                                    return(
                                    <>
                                        <div className="home-activity-entry">
                                            {activity.message}
                                        </div>
                                        <Divider />
                                    </>
                                    )
                                })}
                                
                            </CardContent>
                            <CardActions>
                                <Button size="small">
                                    View All
                                </Button>
                            </CardActions>
                        </Card>
                      
                    </Grid>
                     * 
                     */}
      </Container>
    </div>
  );
};

export default Home;
