import {
  Card,
  Divider,
  Tabs,
  Tab,
  Table,
  CardContent,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableFooter,
  TablePagination,
  TableHead,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Auth from '../../auth';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const HomeFailedPaymentsTable: React.FC<{ classes: any }> = ({ classes }) => {
  const [users, setUsers] = useState<any | null>(null);
  const [count, setCount] = useState<any | null>(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageSize, setPageSize] = useState<any | null>(10);
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState(
    moment().subtract(3, 'months').format('YYYY-MM-DD').toString()
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString()
  );
  const [args, setArgs] = useState<string>(
    `failed_payments=${startDate}to${endDate}`
  );
  const [showFilter, setShowFilter] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage !== page) {
      console.log('PAGE: ', newPage + 1);
      setPage(newPage);
    }
  };

  useEffect(() => {
    let newArgs = `?page_size=${pageSize}${args !== '' ? `&${args}` : ''}${
      page === 0
        ? ''
        : `&page=${page + 1}&failed_payments=${startDate}to${endDate}`
    }
        `;
    loadUsers(newArgs);
  }, [page, args, pageSize]);

  useEffect(() => {
    console.log(startDate + ' ' + endDate);
    setArgs(`failed_payments=${startDate}to${endDate}`);
  }, [startDate, endDate]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setType(newValue);
  };

  const history = useHistory();
  useEffect(() => {
    if (users === null) {
      let newArgs = `?page_size=${pageSize}${args !== '' ? `&${args}` : ''}`;
      loadUsers(newArgs);
    }
  }, []);

  const loadUsers = (args: any) => {
    Auth.dashboard.getUsers(
      args,
      (resp) => {
        setCount(resp.count);
        setUsers(resp.results);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6}>
            <Typography
              color="primary"
              style={{
                fontSize: '16px',
                fontStyle: 'bold',
                padding: '10px 0px',
              }}
            >
              Failed Payments
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            item
            alignItems="center"
            justify="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                id="date"
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e: any) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="date"
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e: any) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <div style={{ paddingTop: '20px' }}>
          <Divider />
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    User
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length > 0 ? (
                users.map((user: any) => {
                  return (
                    <TableRow
                      className="user-row"
                      key={user.id}
                      onClick={() => history.push(`/admin/user/${user.id}`)}
                    >
                      <TableCell>
                        <Typography
                          style={{ fontSize: '16px', fontWeight: 'bold' }}
                        >
                          {user.email}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>No users</TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TablePagination
                                              onPageChange={handleChangePage}
                                              onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default HomeFailedPaymentsTable;
