import { Card, Tabs, Tab, Table, CardContent, TableContainer, TableBody, TableRow, TableCell, Typography, TableFooter, TablePagination, TableHead, Divider, Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import Auth from "../../auth";
import URL from '../../services/url';


const HomeApplyingUsersTable: React.FC<{classes: any}> = ({classes}) => {
    const [args, setArgs] = useState<string>('applying_for_installment=true')
    const [users, setUsers] = useState<any | null>(null)
    const [count, setCount] = useState<any | null>(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const [showFilter, setShowFilter] = useState(false);

    

    const handleChangePage = (event: unknown, newPage: number) => {
        if(newPage !== page){
            console.log('PAGE: ',newPage+1)
            setPage(newPage);
           
        }
    };

    useEffect(() => {
        let newArgs = `?page_size=${pageSize}&${(page === 0 )? '' : `&page=${page+1}`}${args !== '' ? `&${args}` : ''}
        `
        loadUsers(newArgs)


    },[args, pageSize,page])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setArgs(newValue);
    }

    const history = useHistory();
    useEffect(() => {
        if(users === null){
            let newArgs = `?page_size=${pageSize}${args !== '' ? `&${args}` : ''}`
            loadUsers(newArgs)
        }
        
    },[])

    const loadUsers = (args: any) => {
        Auth.dashboard.getUsers(
            args,
            (resp) => {
                setCount(resp.count)
                setUsers(resp.results)
            },
            (e) => {
                console.log(e)
            }
        )
    }

    return(
        
        <Card className={classes.card}>
            <CardContent>
                
                <Grid container direction="column" justify="space-between" alignItems="flex-start">
                    <Grid item xs={12} sm={6}>
                        <Typography color="primary" style={{fontSize: '16px', fontStyle: 'bold', padding: '10px 0px'}}>Applying Users</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                
                        <FormControl variant="filled">
                            <InputLabel htmlFor="filter-status-select">Status</InputLabel>
                            <Select
                                onChange={(e: any)=> setArgs(e.target.value)}
                                value={args}
                                color="primary"
                                autoWidth
                                fullWidth
                                style={{minWidth: '140px'}}
                                label="Status"
                                inputProps={{
                                    name: 'filter-status-select',
                                    id: 'filter-status-select',
                                }}
                            >
                                <MenuItem value='applying_for_installment=true'>
                                    Installment
                                </MenuItem>
                                <MenuItem value='applying_for_merchant=true'>
                                    Merchant
                                </MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                  

                <div style={{paddingTop: '20px'}}>
                    <Divider />
                </div>

                <TableContainer>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                        User
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                        Date Applied
                                    </Typography>
                                </TableCell>
                            
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.length > 0 ? users.map((user: any) => {
                                return(
                                    <TableRow className="user-row" key={user.id} onClick={() => history.push(`/admin/user/${user.id}`)}>
                                        <TableCell>
                                            <Typography style={{fontSize: '16px', fontWeight: 'bold'}} >
                                                {user.email}
                                            </Typography>
                                        </TableCell>
                                      
                                        <TableCell>
                                            <Typography style={{fontSize: '16px', fontWeight: 'bold'}} >
                                                {moment(args === "applying_for_merchant=true" ? user.merchant_application_date : user.installment_authorize_application_date).format("MMMM D, YYYY h:mm A")}
                                            </Typography>
                                        </TableCell>
                            
                                        
                                        
                                    </TableRow>
                                )
                            }): (
                                <TableRow>
                                    <TableCell>
                                        No users
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[5]}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

export default HomeApplyingUsersTable;