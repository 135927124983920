import AES from 'crypto-js/aes';
import { enc, lib, mode, pad } from 'crypto-js';
import _ from 'lodash';

const SECRET_KEY = 'VSNeYilDCImX0hqT4avYLdsKmFIR7hojhi+zBKM35D7=';

class CryptographyService {
  encryptData = (input: string) => {
    const key = enc.Base64.parse(SECRET_KEY);
    const iv = lib.WordArray.random(16);

    const encrypted = AES.encrypt(input, key, {
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
      blockSize: 16,
    });

    return {
      data: enc.Base64.stringify(encrypted.ciphertext),
      iv: enc.Base64.stringify(iv),
    };
  };

  decryptData = (encrypted: string, iv: string) => {
    const key = enc.Base64.parse(SECRET_KEY);
    const parsedIV = enc.Base64.parse(iv);

    const decrypted = AES.decrypt(encrypted, key, {
      iv: parsedIV,
      mode: mode.CBC,
      padding: pad.Pkcs7,
      blockSize: 16,
    });

    return _.replace(decrypted.toString(enc.Utf8), /\0/g, '');
  };

  encryptURLParam = (param: string) => {
    const key = enc.Base64.parse(SECRET_KEY);
    const ciphertext = AES.encrypt(param, key);

    return encodeURIComponent(ciphertext.toString());
  };

  decryptURLParam = (encryptedParam: string) => {
    const key = enc.Base64.parse(SECRET_KEY);

    return AES.decrypt(encryptedParam, key).toString(enc.Utf8);
  };
}

export const CryptographyServiceInstance = new CryptographyService();
