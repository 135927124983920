import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Card,
  CardContent,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  AppBar,
  Box,
  Toolbar,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  FormLabel,
  Button,
} from '@material-ui/core';
import { Row } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MoneyLabel, TransactionName } from '../../components';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useMutation } from '@tanstack/react-query';
import URL from '../../services/url';
import { ApiRequest } from '../../services/http';

interface Column {
  id:
    | 'check'
    | 'email'
    | 'amount'
    | 'is_requested'
    | 'username'
    | 'first_name'
    | 'last_name'
    | 'date_joined';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

interface PayoutInput {
  type: string;
  payout_ids: number[];
}

const columns: Column[] = [
  { id: 'check', label: '', minWidth: 25 },
  { id: 'amount', label: 'Amount', minWidth: 25 },
  { id: 'email', label: 'Transaction', minWidth: 50 },
  { id: 'username', label: 'Status', minWidth: 50 },
  {
    id: 'first_name',
    label: 'Merchant',
    minWidth: 50,
  },
  {
    id: 'last_name',
    label: 'Requested',
    minWidth: 100,
  },
  {
    id: 'date_joined',
    label: 'Date Created',
    minWidth: 50,
  },
];

const Payouts: React.FC<{
  setLoadingOverlay: (e?: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const history = useHistory();
  const [args, setArgs] = useState<any | null>('');
  const [count, setCount] = useState<any | null>(0);
  const [payouts, setPayouts] = useState<any | null>(null);
  const [pageSize, setPageSize] = useState<any | null>(10);

  const [payoutChecked, setPayoutChecked] = useState<number[]>([]);

  const [gte, setGte] = useState<any | null>('');
  const [lte, setLte] = useState<any | null>(
    moment().format('YYYY-MM-DD').toString()
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [openPassword, setOpenPassword] = useState(false);

  const [is_request_sent, setIsRequestSent] = useState(false);
  const [status, setStatus] = useState('');
  const [category, setCategory] = useState('');
  const [categoryList, setCategoryList] = useState<any | null>(null);
  const [payoutType, setPayoutType] = useState('');
  const [payoutTypeDialogOpen, setPayoutTypeDialogOpen] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage !== page) {
      console.log('PAGE: ', newPage + 1);
      setPayoutChecked([]);
      setPage(newPage);
      if (newPage + 1 === 1) {
        setArgs(`page=1`);
      } else {
        setArgs(`page=${newPage + 1}`);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageSize(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (payouts === null && categoryList === null) {
      setLoadingOverlay(true);
      loadPayouts('');
      Auth.dashboard.getCategory(
        '',
        (response) => {
          setCategoryList(response.results);
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }, [payouts, categoryList]);

  useEffect(() => {
    let newArgs =
      `?page_size=${pageSize}` +
      `${args !== '' ? `&&${args}` : ''}` +
      `${is_request_sent ? `&is_request_sent=true` : ''}` +
      `${status !== '' ? `&status=${status}` : ''}` +
      `${category !== '' ? `&category=${category}` : ''}` +
      ``;
    loadPayouts(newArgs);
  }, [args, pageSize, status, is_request_sent, category]);

  useEffect(() => {
    console.log(payoutChecked);
  }, [payoutChecked]);

  const refresh = () => {
    let newArgs =
      `?page_size=${pageSize}` +
      `${args !== '' ? `&&${args}` : ''}` +
      `${is_request_sent ? `&is_request_sent=true` : ''}` +
      `${status !== '' ? `&status=${status}` : ''}` +
      `${category !== '' ? `&category=${category}` : ''}` +
      ``;
    setPayoutChecked([]);
    loadPayouts(newArgs);
  };

  const loadPayouts = (params: any) => {
    setLoadingOverlay(true);
    Auth.dashboard.getPayouts(
      params,
      (resp) => {
        console.log(resp);
        console.log(resp.results);
        setLoadingOverlay(false);
        setCount(resp.count);
        setPayouts(resp.results);
      },
      (e) => {
        setLoadingOverlay(false);
        console.log(e);
      }
    );
  };

  const handleRemove = (id: any) => {
    const newList = payoutChecked.filter((item: any) => item !== id);

    setPayoutChecked(newList);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayoutType(event.target.value);
  };

  const handlePayoutTypeDialogClose = () => {
    setPayoutTypeDialogOpen(false);
  };

  const handleOpenPayoutTypesDialog = () => {
    if (payoutChecked.length >= 1) {
      setPayoutTypeDialogOpen(true);
    }
  };

  const { mutate: mutatePayout, isLoading } = useMutation(
    (data: PayoutInput) => {
      return ApiRequest.post(URL.DASHBOARD.SEND_PAYOUTS, data);
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: (e) => {
        setError('Something went wrong');
        setLoadingOverlay(false);
        setOpenErrorDialog(true);
      },
    }
  );

  const sendPayouts = () => {
    mutatePayout({
      type: payoutType,
      payout_ids: payoutChecked,
    });
  };

  return (
    <div className="page-content users">
      <Container>
        <Row></Row>
        <Row>
          <Card>
            <CardContent>
              <AppBar
                position="static"
                style={{ background: 'transparent', boxShadow: 'none' }}
              >
                <Toolbar>
                  <Typography
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    Payouts
                  </Typography>
                  <Box display="flex" flexGrow={1} />
                  <div
                    className={`button-tool${
                      payoutChecked.length < 1 ? '-disabled' : ''
                    }`}
                  >
                    <Typography
                      onClick={handleOpenPayoutTypesDialog}
                      style={{ fontSize: 16, color: 'white' }}
                    >
                      Send Payouts
                    </Typography>
                  </div>
                </Toolbar>
              </AppBar>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    color="primary"
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Filters
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      justify="space-between"
                      item
                    >
                      <Grid item xs={12} sm={2}>
                        <Typography
                          style={{ fontSize: '16px', fontWeight: 'bold' }}
                          color="primary"
                        >
                          Date Filter:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          id="date"
                          label="Start Date"
                          type="date"
                          fullWidth
                          value={gte}
                          onChange={(e: any) => setGte(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          id="date"
                          label="End Date"
                          type="date"
                          fullWidth
                          value={lte}
                          onChange={(e: any) => setLte(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="flex-start"
                      item
                    >
                      <Grid item xs={12} sm={2}>
                        <Typography
                          style={{ fontSize: '16px', fontWeight: 'bold' }}
                          color="primary"
                        >
                          Misc Filter:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={is_request_sent}
                              onChange={() =>
                                setIsRequestSent(!is_request_sent)
                              }
                              name="active"
                            />
                          }
                          label={
                            <Typography style={{ fontSize: '16px' }}>
                              Request Sent
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControl variant="filled">
                          <InputLabel htmlFor="filter-status-select">
                            Status
                          </InputLabel>
                          <Select
                            onChange={(e: any) => setStatus(e.target.value)}
                            value={status}
                            color="primary"
                            autoWidth
                            fullWidth
                            style={{ minWidth: '140px' }}
                            label="Status"
                            inputProps={{
                              name: 'filter-status-select',
                              id: 'filter-status-select',
                            }}
                          >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value={'PENDING'}>Pending</MenuItem>

                            <MenuItem value={'COMPLETE'}>Complete</MenuItem>

                            <MenuItem value={'CANCELLED'}>Cancelled</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControl variant="filled">
                          <InputLabel htmlFor="filter-status-select">
                            Category
                          </InputLabel>
                          <Select
                            onChange={(e: any) => setCategory(e.target.value)}
                            value={category}
                            color="primary"
                            autoWidth
                            fullWidth
                            style={{ minWidth: '140px' }}
                            label="Category"
                            inputProps={{
                              name: 'filter-status-select',
                              id: 'filter-status-select',
                            }}
                          >
                            <MenuItem value="">None</MenuItem>
                            {categoryList &&
                              categoryList.map((cat: any) => (
                                <MenuItem value={cat.id}>{cat.name}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.minWidth }}
                        >
                          <Typography
                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                            color="primary"
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payouts &&
                      payouts.map((payout: any) => {
                        return (
                          <TableRow
                            className="user-row"
                            onClick={() => {
                              if (payoutChecked.includes(payout.id)) {
                                handleRemove(payout.id);
                              } else {
                                setPayoutChecked([...payoutChecked, payout.id]);
                              }
                            }}
                          >
                            <TableCell>
                              <Checkbox
                                checked={payoutChecked?.includes(payout.id)}
                                onChange={() => {
                                  if (payoutChecked.includes(payout.id)) {
                                    handleRemove(payout.id);
                                  } else {
                                    setPayoutChecked([
                                      ...payoutChecked,
                                      payout.id,
                                    ]);
                                  }
                                }}
                                name="active"
                              />
                            </TableCell>
                            <TableCell>
                              <MoneyLabel
                                style={'money-label'}
                                value={payout.amount}
                                isMoney={true}
                              />
                            </TableCell>
                            <TableCell>{payout.additional}</TableCell>
                            <TableCell>{payout.status}</TableCell>
                            <TableCell>{payout.merchant}</TableCell>
                            <TableCell>
                              {payout.is_request_sent ? (
                                <CheckCircleIcon
                                  fontSize="small"
                                  style={{ fill: 'green' }}
                                />
                              ) : (
                                <CancelIcon
                                  fontSize="small"
                                  style={{ fill: 'red' }}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {moment(payout.created).format(
                                'MMMM D, YYYY h:mm A'
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Row>
      </Container>
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
      <Dialog open={payoutTypeDialogOpen} onClose={handlePayoutTypeDialogClose}>
        <DialogTitle id="simple-dialog-title">Select Payout type</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="manual"
                control={<Radio color="primary" />}
                label="Manual"
                labelPlacement="start"
              />
              <FormControlLabel
                value="dragonpay"
                control={<Radio color="primary" />}
                label="Dragonpay"
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
          <div
            className={`button-tool${
              payoutChecked.length < 1 ? '-disabled' : ''
            }`}
          >
            <div style={{ display: 'flex' }}>
              {isLoading && <ClipLoader color="#FFFFFF" size={30} />}
              <Typography
                onClick={sendPayouts}
                style={{ fontSize: 16, color: 'white', margin: 'auto' }}
              >
                Continue
              </Typography>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      ;
    </div>
  );
};

export default Payouts;
