const HOST = 'https://dev.api.4gives.me';
// const HOST = 'https://app4gives-sandbox.herokuapp.com';
// const HOST = 'http://localhost:8000';
const SERVER = `${HOST}/api/v1`;

const URL = {
  SERVER: SERVER,
  LOGIN: `${SERVER}/dashboard/login/`,
  REGISTRATION: `${SERVER}/users/user/register/`,
  USER: {
    PROFILE: `${SERVER}/users/user/`,
    USERS: `${SERVER}/users/UserListView/`,
    VERIFY_EMAIL: `${SERVER}/users/user/verify/`,
  },
  DASHBOARD: {
    ACTIVITIES: `${SERVER}/dashboard/activities`,
    BASE: `${SERVER}/dashboard/`,
    USERS: `${SERVER}/dashboard/users/`,
    TRANSACTIONS: `${SERVER}/dashboard/transactions`,
    PROPOSALS: `${SERVER}/dashboard/proposals/`,
    SERVICE_TYPES: `${SERVER}/dashboard/payment_type`,
    CATEGORY: `${SERVER}/dashboard/category/`,
    STAFFS: `${SERVER}/dashboard/staffs/`,
    STAFF: `${SERVER}/dashboard/staff/`,
    STAFF_CREATE: `${SERVER}/dashboard/staffs/create/`,
    SUMMARY: `${SERVER}/dashboard/summary`,
    REJECT_APPLICATION: `${SERVER}/dashboard/reject_application/`,
    PAYMENT_SOURCES: `${SERVER}/dashboard/payment_sources`,
    PAYMENT_SOURCE: `${SERVER}/dashboard/payment_source`,
    PAYMENT_SCHEDULES: `${SERVER}/dashboard/payment_schedules`,
    PAYOUTS: `${SERVER}/dashboard/payouts`,
    PAYMENT_OBJECT: `${SERVER}/dashboard/payment/`,
    SEND_PAYOUTS: `${SERVER}/dashboard/send_payouts/`,
    NOTIFICATIONS: {
      ADS: `${SERVER}/notifications/ad-images`,
    },
    EMPLOYEE_CREATE: `${SERVER}/dashboard/employee/create/`,
    EMPLOYEE_BULK_UPLOAD: `${SERVER}/dashboard/employee/upload/`,
    EMPLOYEE_ADD_COMPANY: `${SERVER}/dashboard/employee/company/create/`,
    COMPANIES: `${SERVER}/dashboard/companies`,
    COMPANY_ADDRESS_CREATE: `${SERVER}/dashboard/company/address/create/`,
    COMPANY_ADDRESS: `${SERVER}/dashboard/company/address`,
    CONSTANCE: `${SERVER}/dashboard/constance`,
  },
  TRANSACTIONS: {
    TRANSACTIONS_BASE: `${SERVER}/transactions`,
    TRANSACTIONS_ACTIVITY: `${SERVER}/transactions/activities`,
    TRANSACTIONS_BUY: `${SERVER}/transactions/buying`,
    TRANSACTIONS_SELL: `${SERVER}/transactions/selling`,
    ACCOUNT_ACTIVATE: `${SERVER}/transactions/source`,
    PAYMENT_SOURCE: `${SERVER}/transactions/source`,

    COMPUTATION: `${SERVER}/transactions/installment/compute/`,
  },
  PROPOSALS: {
    PROPOSAL: `${SERVER}/transactions/proposal/`, //should accept int id
    PROPOSAL_ACCEPT: `${SERVER}/transactions/proposal/accept`,
    PROPOSALS_SELL: `${SERVER}/transactions/proposal/sell`,
    PROPOSALS_BUY: `${SERVER}/transactions/proposal/buy`,
    PROPOSAL_SELL: `${SERVER}/transactions/proposal/create/sell`,
    PROPOSAL_BUY: `${SERVER}/transactions/proposal/create/buy`,
  },
  PAYMAYA: {
    PAYMAYA_CARDS: `${SERVER}/transactions/paymaya/cards`,
    PAYMAYA_ACCOUNT: `${SERVER}/transactions/paymaya/account`,
  },

  EVENTS: {
    DASHBOARD: `${HOST}/events/dashboard`,
  },
  COMPANIES: {
    COMPANIES_LIST: `${SERVER}/companies`,
  },
  SERVER_TIME: `${SERVER}/core/server_time`,
};

export default URL;
